import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { updateCategory } from '../../features/category/categorySlice';
import { useDispatch, useSelector } from 'react-redux';

const CategoryModal = (catg) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);

    const { categories } = useSelector(
        (state) => state.category
    );

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setValidated(false);
        setfeedbackNoChnages(false)
        setCategory({
            nameAr: '',
            nameHe: ''
        })
    }

    const [feedbackCategoryNameAr, setfeedbackCategoryNameAr] = useState('');
    const [feedbackCategoryNameHe, setfeedbackCategoryNameHe] = useState('');
    const [feedbackNoChnages, setfeedbackNoChnages] = useState(false);

    const [category, setCategory] = useState({
        nameAr: '',
        nameHe: ''
    });

    const categoryHanlder = (e) => {
        e.target.setCustomValidity('');
        setCategory((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };
    const onSubmit = (e) => {
        e.preventDefault();
        setValidated(true);

        if (categoryNameArIsExistValidation(category.nameAr)) {
            e.target.elements.updateCatInputNameAr.setCustomValidity(' ');
            e.target.elements.updateCatInputNameAr.reportValidity();
            setfeedbackCategoryNameAr('Category name already exist')
        }
        if (categoryNameHeIsExistValidation(category.nameHe)) {
            e.target.elements.updateCatInputNameHe.setCustomValidity(' ');
            e.target.elements.updateCatInputNameHe.reportValidity();
            setfeedbackCategoryNameHe('Category name already exist')
        }
        if (categoryNullValidation()) {
            setfeedbackNoChnages(true);
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (!e.currentTarget.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const obj = {
            id: catg._id,
            categoryNameAr: category.nameAr,
            categoryNameHe: category.nameHe,
        }

        dispatch(updateCategory(obj));
        handleClose();
    };

    const categoryNameArIsExistValidation = (name) => {
        const categoryNameIsExist = categories.find((catg) => catg.nameAr === name);
        if (categoryNameIsExist) {
            return true;
        }
        return false;
    }
    const categoryNameHeIsExistValidation = (name) => {
        const categoryNameIsExist = categories.find((catg) => catg.nameHe === name);
        if (categoryNameIsExist) {
            return true;
        }
        return false;
    }

    const categoryNullValidation = () => {
        for (const i in category) {
            if (category[i] !== '') {
                return false
            }
        }
        return true;
    }

    return (
        <>
            <div className='flexColStart p-card catdiv' onClick={handleShow}>
                <div>
                    <div>
                        <span className='primaryText'>{catg.nameAr}</span>
                        <br />
                        <span className='primaryText'>{catg.nameHe}</span>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size='lg' backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>Update Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="updateCatInputNameAr">
                            <Form.Label>Category Name In Arabic</Form.Label>
                            <Form.Control className='marginBottom' dir="rtl" type="text" name='nameAr' placeholder={catg.nameAr} onChange={categoryHanlder} autoFocus />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackCategoryNameAr}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="updateCatInputNameHe">
                            <Form.Label>Category Name In Hebrew</Form.Label>
                            <Form.Control className='marginBottom' dir="rtl" type="text" name='nameHe' placeholder={catg.nameHe} onChange={categoryHanlder} />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackCategoryNameHe}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type='submit'>
                            Save Changes
                        </Button>
                        <div className={feedbackNoChnages ? 'display-block' : 'display-none'}>No changes were made</div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CategoryModal
