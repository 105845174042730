import axios from 'axios';

const API_URL = '/api/categories';

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('UserToken')}`,
    },
};

const getAllCategories = async () => {
    const response = await axios.get(process.env.REACT_APP_HOST_URL + API_URL + '/getcategories');
    return response.data;
}
const addNewCategory = async (category) => {
    const response = await axios.post(process.env.REACT_APP_HOST_URL + API_URL + '/addcategory', category, config);
    return response.data;
}
const updateCategory = async (obj) => {
    const category = {
        nameAr: obj.categoryNameAr,
        nameHe: obj.categoryNameHe
    }
    const response = await axios.put(process.env.REACT_APP_HOST_URL + API_URL + '/updatecategory/' + obj.id, category, config);
    return response.data;
}
const categoryService = {
    getAllCategories,
    addNewCategory,
    updateCategory
}

export default categoryService;