import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import categoryReducer from "../features/category/categorySlice";
import subcategoryReducer from "../features/subcategory/subcategorySlice";
import productReducer from "../features/product/productSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        category: categoryReducer,
        subcategory: subcategoryReducer,
        product: productReducer,
    },
});