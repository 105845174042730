import { Outlet, createBrowserRouter } from 'react-router-dom';
import HomePage from '../components/Home/HomePage';
import Error404 from '../components/Error404/Error404';
import Navbar from '../components/Navbar/Navbar';
import Products from '../components/Products/Products';
import Categories from '../components/Categories/Categories';
import SubCategories from '../components/SubCategories/SubCategories';
import Login from '../components/Login/Login';

export const router = createBrowserRouter([
    {
        path: "/",
        element: <>
            <Login />
            <Navbar />
            <Outlet />
        </>,
        errorElement: <Error404 />,
        children: [
            {
                path: '/home',
                element: <>
                    <HomePage />
                </>
            },
            {
                path: '/products',
                element: <>
                    <Products />
                </>
            },
            {
                path: '/categories',
                element: <>
                    <Categories />
                </>
            },
            {
                path: '/subcategories',
                element: <>
                    <SubCategories />
                </>
            }
        ]
    },
]);