import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "./productService";

const initialState = {
    products: [],
    isSuccessProduct: false,
    isErrorProduct: false,
    isLoadingProduct: false,
    messageProduct: "",
};

//Getting All Products
export const getAllProducts = createAsyncThunk("product/getAllProducts", async (thunkAPI) => {
    try {
        return await productService.getAllProducts();
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
//Add New Product
export const addNewProduct = createAsyncThunk("product/addNewProduct", async (obj, thunkAPI) => {
    try {
        return await productService.addNewProduct(obj);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
//Update Product
export const updateProduct = createAsyncThunk("product/updateProduct", async (obj, thunkAPI) => {
    try {
        return await productService.updateProduct(obj);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        alert(message);
        return thunkAPI.rejectWithValue(message);
    }
});
//Getting All Products
export const deleteProduct = createAsyncThunk("product/deleteProduct", async (id, thunkAPI) => {
    try {
        return await productService.deleteProduct(id);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        resetProduct: (state) => {
            state.isLoadingProduct = false;
            state.isErrorProduct = false;
            state.isSuccessProduct = false;
            state.messageProduct = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllProducts.pending, (state) => {
                state.isLoadingProduct = true;
            })
            .addCase(getAllProducts.fulfilled, (state, action) => {
                state.isLoadingProduct = false;
                state.isSuccessProduct = true;
                state.products = action.payload.data;
            })
            .addCase(getAllProducts.rejected, (state, action) => {
                state.isLoadingProduct = false;
                state.isErrorProduct = true;
                state.messageProduct = action.payload.Message;
                state.products = [];
            })
            .addCase(addNewProduct.pending, (state) => {
                state.isLoadingProduct = true;
            })
            .addCase(addNewProduct.fulfilled, (state, action) => {
                state.isLoadingProduct = false;
                state.isSuccessProduct = true;
                state.products = [action.payload.data, ...state.products];
            })
            .addCase(addNewProduct.rejected, (state, action) => {
                state.isLoadingProduct = false;
                state.isErrorProduct = true;
                state.messageProduct = action.payload.Message;
            })
            .addCase(updateProduct.pending, (state) => {
                state.isLoadingProduct = true;
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.isLoadingProduct = false;
                state.isSuccessProduct = true;
                state.products = state.products.map(element => {
                    if (element._id === action.payload.data._id) {
                        return action.payload.data;
                    }
                    return element;
                });
            })
            .addCase(updateProduct.rejected, (state, action) => {
                state.isLoadingProduct = false;
                state.isErrorProduct = true;
                state.messageProduct = action.payload.Message;
            })
            .addCase(deleteProduct.pending, (state) => {
                state.isLoadingProduct = true;
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.isLoadingProduct = false;
                state.isSuccessProduct = true;
                state.products = state.products.filter((prod) => prod._id !== action.payload.data._id)
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                state.isLoadingProduct = false;
                state.isErrorProduct = true;
                state.messageProduct = action.payload.Message;
            })
    },
});

export const { resetProduct } = productSlice.actions;
export default productSlice.reducer;