import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "../../features/auth/authSlice";
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css'

const HomePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(
        (state) => state.auth
    );
    useEffect(() => {
        if (!user) {
            navigate("/");
        }
        dispatch(reset());
    }, [user, navigate, dispatch]);

    return (
        <>
            <div className="home-page">
                <img src={window.location.origin + '/assets/images/backgroundPicture.png'} alt="montekids" className='backgroundImg' />
            </div>
        </>
    )
}

export default HomePage;
