import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCategory } from '../../features/category/categorySlice';

const AddCategory = (nameAr, nameHe) => {
    const { categories } = useSelector(
        (state) => state.category
    );
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setValidated(false);
    }

    const [feedbackCategoryNameAr, setfeedbackCategoryNameAr] = useState('');
    const [feedbackCategoryNameHe, setfeedbackCategoryNameHe] = useState('');

    const [category, setCategory] = useState({
        nameAr: nameAr,
        nameHe: nameHe
    });

    const categoryHanlder = (e) => {
        e.target.setCustomValidity('');
        setCategory((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };
    const onSubmit = (e) => {
        e.preventDefault();
        setValidated(true);

        if (categoryNameArIsExistValidation(category.nameAr)) {
            e.target.elements.inputNameAr.setCustomValidity(' ');
            e.target.elements.inputNameAr.reportValidity();
            setfeedbackCategoryNameAr('Category name already exist')
        }
        if (categoryNameHeIsExistValidation(category.nameHe)) {
            e.target.elements.inputNameHe.setCustomValidity(' ');
            e.target.elements.inputNameHe.reportValidity();
            setfeedbackCategoryNameHe('Category name already exist');
        }

        if (!e.currentTarget.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        dispatch(addNewCategory(category));
        handleClose();
    };
    const categoryNameArIsExistValidation = (name) => {
        const categoryNameIsExist = categories.find((catg) => catg.nameAr === name);
        if (categoryNameIsExist) {
            return true;
        }
        return false;
    }
    const categoryNameHeIsExistValidation = (name) => {
        const categoryNameIsExist = categories.find((catg) => catg.nameHe === name);
        if (categoryNameIsExist) {
            return true;
        }
        return false;
    }

    return (
        <>
            <button onClick={handleShow} className='button add-button'>Add New Category</button>

            <Modal show={show} onHide={handleClose} size='lg' backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={onSubmit}>
                        <Form.Group className='mb-3' controlId="inputNameAr">
                            <Form.Label>Category Name In Arabic</Form.Label>
                            <Form.Control className='marginBottom' dir="rtl" required type="text" name='nameAr' placeholder="Category Name In Arabic" onChange={categoryHanlder} autoFocus />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackCategoryNameAr}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="inputNameHe">
                            <Form.Label>Category Name In Hebrew</Form.Label>
                            <Form.Control className='marginBottom' dir="rtl" required type="text" name='nameHe' placeholder="Category Name In Hebrew" onChange={categoryHanlder} />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackCategoryNameHe}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type='submit'>
                            Add Category
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddCategory
