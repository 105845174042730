import axios from 'axios';

const API_URL = '/api/subcategories';

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('UserToken')}`,
    },
};

const getAllSubCategories = async () => {
    const response = await axios.get(process.env.REACT_APP_HOST_URL + API_URL + '/getsubcategories');
    return response.data;
}
const addNewSubCategory = async (obj) => {
    const subcategory = {
        nameAr: obj.subcategoryNameAr,
        nameHe: obj.subcategoryNameHe
    }
    const response = await axios.post(process.env.REACT_APP_HOST_URL + API_URL + '/addsubcategory/' + obj.categoryId, subcategory, config);
    return response.data;
}
const updateSubCategory = async (obj) => {
    const subcategory = {
        nameAr: obj.subcategoryNameAr,
        nameHe: obj.subcategoryNameHe,
        category: obj.category
    }
    const response = await axios.put(process.env.REACT_APP_HOST_URL + API_URL + '/updatesubcategory/' + obj.id, subcategory, config);
    return response.data;
}
const categoryService = {
    getAllSubCategories,
    addNewSubCategory,
    updateSubCategory
}

export default categoryService;