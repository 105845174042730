import { useEffect } from 'react'
import './SubCategories.css'
import SubCategoryModal from './SubCategoryModal';
import AddSubCategory from './AddSubCategory';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { reset } from '../../features/auth/authSlice';
import { getAllSubCategories } from '../../features/subcategory/subcategorySlice';
import { Spinner } from 'react-bootstrap';
import { getAllCategories } from '../../features/category/categorySlice';

const SubCategories = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(
        (state) => state.auth
    );
    const { categories } = useSelector(
        (state) => state.category
    );
    const { subCategories, isLoadingSubCategory } = useSelector(
        (state) => state.subcategory
    );
    useEffect(() => {
        if (!user) {
            navigate("/");
        }
        if (categories.length === 0) {
            dispatch(getAllCategories());
        }
        if (subCategories.length === 0) {
            dispatch(getAllSubCategories());
        }
        dispatch(reset());
    }, [user, categories.length, subCategories.length, dispatch, navigate]);

    return (
        <>
            {isLoadingSubCategory ? <Spinner /> : (
                <div>
                    <div>
                        <AddSubCategory />
                    </div>
                    <div>
                        <div>
                            <div className='flexCenter paddings products'>
                                {subCategories?.map((subCategory, i) => (
                                    <SubCategoryModal key={i} {...subCategory} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default SubCategories
