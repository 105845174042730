import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubCategory } from '../../features/subcategory/subcategorySlice';

const SubCategoryModal = (subcatg) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const { categories } = useSelector(
        (state) => state.category
    );
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setValidated(false);
        setShow(true);
        setfeedbackNoChnages(false);
        setSubCategory({
            nameAr: '',
            nameHe: ''
        });
        setCategoryId('');
    }

    const [feedbackSubcategoryNameAr, setfeedbackSubcategoryNameAr] = useState('');
    const [feedbackSubcategoryNameHe, setfeedbackSubcategoryNameHe] = useState('');
    const [feedbackNoChnages, setfeedbackNoChnages] = useState(false);

    const { subCategories } = useSelector(
        (state) => state.subcategory
    );

    const [subcategory, setSubCategory] = useState({
        nameAr: '',
        nameHe: ''
    });
    const [categoryId, setCategoryId] = useState('');

    const subcategoryHanlder = (e) => {
        e.target.setCustomValidity('');
        setSubCategory((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };
    const categoryIdHanlder = (e) => {
        setCategoryId(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setValidated(true);

        if (subCategoryNameArIsExistValidation(subcategory.nameAr)) {
            e.target.elements.updateSubInputNameAr.setCustomValidity(' ');
            e.target.elements.updateSubInputNameAr.reportValidity();
            setfeedbackSubcategoryNameAr('Sub category name already exist')
        }
        if (subCategoryNameHeIsExistValidation(subcategory.nameHe)) {
            e.target.elements.updateSubInputNameHe.setCustomValidity(' ');
            e.target.elements.updateSubInputNameHe.reportValidity();
            setfeedbackSubcategoryNameHe('Sub category name already exist')
        }

        if (subCategoryNullValidation()) {
            setfeedbackNoChnages(true);
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (!e.currentTarget.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const obj = {
            id: subcatg._id,
            subcategoryNameAr: subcategory.nameAr,
            subcategoryNameHe: subcategory.nameHe,
            category: categoryId
        }
        
        dispatch(updateSubCategory(obj));
        handleClose();
    };

    const categoryName = () => {
        const catg = categories.find((i) => (i._id === subcatg.category));
        return `${catg.nameAr + ' - ' + catg.nameHe}`
    }

    const subCategoryNameArIsExistValidation = (name) => {
        const subCategoryNameIsExist = subCategories.find((catg) => catg.nameAr === name);
        if (subCategoryNameIsExist) {
            return true;
        }
        return false;
    }
    const subCategoryNameHeIsExistValidation = (name) => {
        const subCategoryNameIsExist = subCategories.find((catg) => catg.nameHe === name);
        if (subCategoryNameIsExist) {
            return true;
        }
        return false;
    }

    const subCategoryNullValidation = () => {
        for (const i in subcategory) {
            if (subcategory[i] !== '') {
                return false
            }
        }
        if (categoryId !== '') {
            return false
        }
        return true;
    }

    return (
        <>
            <div className='flexColStart p-card subcatdiv' onClick={handleShow}>
                <div>
                    <div>
                        <span className='primaryText'>{subcatg.nameAr}</span>
                        <br />
                        <span className='primaryText'>{subcatg.nameHe}</span>
                        <br />
                        <span className='primaryText' style={{ color: 'gray' }}>{categories.length !== 0 ? categoryName() : ""}</span>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size='lg' backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>Update Sub Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="updateSubInputNameAr">
                            <Form.Label>Sub Category Name In Arabic</Form.Label>
                            <Form.Control className='marginBottom' dir="rtl" type="text" name="nameAr" placeholder={subcatg.nameAr} onChange={subcategoryHanlder} autoFocus />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackSubcategoryNameAr}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="updateSubInputNameHe">
                            <Form.Label>Sub Category Name In Hebrew</Form.Label>
                            <Form.Control className='marginBottom' dir="rtl" type="text" name="nameHe" placeholder={subcatg.nameHe} onChange={subcategoryHanlder} />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackSubcategoryNameHe}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Select dir="rtl" aria-label="Default select example" onChange={categoryIdHanlder}>
                                <option></option>
                                {categories?.map((category, i) => (
                                    <option key={i} name="categoryId" value={category._id}>{category.nameAr + ' - ' + category.nameHe}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Button variant="primary" type='submit'>
                            Save Changes
                        </Button>
                        <div className={feedbackNoChnages ? 'display-block' : 'display-none'}>No changes were made</div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SubCategoryModal
