import React from 'react';

const Error404 = () => {
    return (
        <>
            Error404
        </>
    )
}

export default Error404;
