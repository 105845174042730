import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Image, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { IoMdRemoveCircleOutline } from 'react-icons/io'
import './Products.css'
import { addNewProduct } from '../../features/product/productSlice';
import InputGroup from 'react-bootstrap/InputGroup';


const AddProduct = ({ minAgeYear, minAgeMonth, maxAgeYear, maxAgeMonth, catalogNumber, nameAr, nameHe, image, video, descriptionAr, descriptionHe, price, salePrice, inStock,isPopular, goalsAr, goalsHe, minAge, maxAge }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setValidated(false);
        setShow(true);
        setImages({});
        product.image = [];
    }

    const [showSpinner, setShowSpinner] = useState(false);

    const { products } = useSelector(
        (state) => state.product
    );

    const { subCategories } = useSelector(
        (state) => state.subcategory
    );

    const [feedbackCatalogNumber, setfeedbackCatalogNumber] = useState('');
    const [feedbackMinAge, setfeedbackMinAge] = useState('');
    const [feedbackSalePrice, setfeedbackSalePrice] = useState('');

    const [product, setProduct] = useState({
        catalogNumber: catalogNumber,
        nameAr: nameAr,
        nameHe: nameHe,
        image: [],
        video: "video",
        descriptionAr: descriptionAr,
        descriptionHe: descriptionHe,
        price: price,
        salePrice: salePrice,
        inStock: inStock,
        isPopular: "false",
        goalsAr: goalsAr,
        goalsHe: goalsHe,
        minAge: minAge,
        maxAge: maxAge,
    });
    const [age, setAge] = useState({
        minAgeYear: minAgeYear,
        minAgeMonth: minAgeMonth,
        maxAgeYear: maxAgeYear,
        maxAgeMonth: maxAgeMonth
    });

    const [subcategoryId, setSubcategoryId] = useState('');

    const productHanlder = (e) => {
        e.target.setCustomValidity('');
        if (e.target.name === 'salePrice') {
            setfeedbackSalePrice("");
        }

        if (e.target.name === 'price' || e.target.name === 'salePrice') {
            setProduct((prevState) => {
                return {
                    ...prevState,
                    [e.target.name]: parseFloat(e.target.value),
                };
            });
        } else {
            setProduct((prevState) => {
                return {
                    ...prevState,
                    [e.target.name]: e.target.value,
                };
            });
        }

    };

    const subCategoryIdHanlder = (e) => {
        setSubcategoryId(e.target.value);
    };

    useEffect(() => {
        if ((age.minAgeYear || age.minAgeYear === 0) && (age.minAgeMonth || age.minAgeMonth === 0) && (age.maxAgeYear || age.maxAgeYear === 0) && (age.maxAgeMonth || age.maxAgeMonth === 0)) {
            mergeMinMaxAge(age);
        }
    }, [age]);

    const ageHanlder = (e) => {
        e.target.setCustomValidity('');
        if (e.target.name === 'minAgeMonth') {
            setfeedbackMinAge("");
        }
        setAge((prevState) => {
            return {
                ...prevState,
                [e.target.name]: parseFloat(e.target.value),
            };
        });
    };

    const saveHandler = (e) => {
        e.preventDefault();
        setValidated(true);
        e.target.elements.inputMinAgeMonth.setCustomValidity('');
        e.target.elements.inputSalePrice.setCustomValidity('');

        if (catalogNumberIsExistValidation(product.catalogNumber)) {
            e.target.elements.inputCatalogNumber.setCustomValidity(' ');
            e.target.elements.inputCatalogNumber.reportValidity();
            setfeedbackCatalogNumber('Catalog number already exist');
        }
        if (ageValidation(product.minAge, product.maxAge)) {
            e.target.elements.inputMinAgeMonth.setCustomValidity(' ');
            e.target.elements.inputMinAgeMonth.reportValidity();
            setfeedbackMinAge('Min age must be smaller than max age')
        }
        if (minMaxAgeBiggerThan12Validation(product.minAge, product.maxAge)) {
            e.target.elements.inputMinAgeMonth.setCustomValidity(' ');
            e.target.elements.inputMinAgeMonth.reportValidity();
            setfeedbackMinAge('Ages cant be bigger than 12Y 0M')
        }
        if (priceValidation(product.price, product.salePrice)) {
            e.target.elements.inputSalePrice.setCustomValidity(' ');
            e.target.elements.inputSalePrice.reportValidity();
            setfeedbackSalePrice('Sale price must be smaller than price')
        }
        if (product.image.length === 0) {
            e.target.elements.inputImage.setCustomValidity(' ');
            e.target.elements.inputImage.reportValidity();
        } else {
            e.target.elements.inputImage.setCustomValidity('');
            e.target.elements.inputImage.reportValidity();
        }

        if (!e.currentTarget.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const obj = {
            ...product,
            subcategoryId: subcategoryId,
        }
        
        dispatch(addNewProduct(obj));
        handleClose();
    };

    const catalogNumberIsExistValidation = (catalogNumber) => {
        const catalogNumberIsExist = products.find((prod) => prod.catalogNumber === catalogNumber);
        if (catalogNumberIsExist) {
            return true;
        }
        return false;
    }

    const mergeMinMaxAge = (age) => {
        const minage = (age.minAgeYear + age.minAgeMonth / 12);
        const maxage = (age.maxAgeYear + age.maxAgeMonth / 12);
        setProduct((prevState) => {
            return {
                ...prevState,
                minAge: parseFloat(minage)
            };
        });
        setProduct((prevState) => {
            return {
                ...prevState,
                maxAge: parseFloat(maxage)
            };
        });
        return true;
    }

    const ageValidation = (min, max) => {
        if (min < 0 || max < 0 || (min !== 0 && !min) || (max !== 0 && !max) || min > 12 || max > 12) {
            return false;
        } else if (min <= max) {
            return false;
        }
        return true;
    }
    const minMaxAgeBiggerThan12Validation = (min, max) => {
        if (min <= 12 && max <= 12) {
            return false;
        }
        return true;
    }
    const priceValidation = (price, salePrice) => {
        if (price < 0 || salePrice < -1 || (price !== 0 && !price) || (salePrice !== 0 && !salePrice)) {
            return false;
        } else if (price > salePrice) {
            return false;
        }
        return true;
    }

    const [images, setImages] = useState({});

    const imageHanlder = (e) => {
        setImages({});
        if (e.target.files.length !== 0) {
            if (e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg") {
                e.target.setCustomValidity('');
                e.target.reportValidity();
                setImages({
                    picturePreview: URL.createObjectURL(e.target.files[0]),
                    pictureAsFile: e.target.files[0]
                })
            }
        }
    };
    const uploadHandler = async (e) => {
        setShowSpinner(true);
        const formData = new FormData();
        formData.append(
            "file",
            images.pictureAsFile
        );
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem('UserToken')}`,
            },
        };
        const response = await axios.post(process.env.REACT_APP_HOST_URL + '/api/s3/upload', formData, config);
        if (response) {
            const arr = [...product.image, response.data.s3ImgUrl];
            setProduct((prevState) => {
                return {
                    ...prevState,
                    image: arr,
                };
            });
        }
        return;
    };
    /**
    * Deletes an image from the product's image array at the specified index.
    * @param {number} i - The index of the image to be deleted.
    * @returns {void}
    */
    const deleteImage = (i) => {
        let arr = [...product.image];
        arr.splice(i, 1);
        setProduct((prevState) => {
            return {
                ...prevState,
                image: arr,
            };
        });
    }

    return (
        <>
            <button onClick={handleShow} className='button add-button'>Add New Product</button>

            <Modal show={show} onHide={handleClose} size='lg' backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>New Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={saveHandler}>
                        <Form.Group className="mb-3" controlId="inputCatalogNumber">
                            <Form.Label>Catalog Number</Form.Label>
                            <Form.Control className='marginBottom' dir="rtl" required type="text" name='catalogNumber' placeholder="Catalog Number" onChange={productHanlder} autoFocus />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackCatalogNumber}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="inputNameAr">
                            <Form.Label>Product Name In Arabic</Form.Label>
                            <Form.Control required dir="rtl" type="text" name='nameAr' placeholder="Product Name In Arabic" onChange={productHanlder} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="inputNameHe">
                            <Form.Label>Product Name In Hebrew</Form.Label>
                            <Form.Control required dir="rtl" type="text" name='nameHe' placeholder="Product Name In Hebrew" onChange={productHanlder} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="inputDesAr">
                            <Form.Label>Product Description In Arabic</Form.Label>
                            <Form.Control required dir="rtl" as="textarea" name='descriptionAr' rows={3} onChange={productHanlder} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="inputDesHe">
                            <Form.Label>Product Description In Hebrew</Form.Label>
                            <Form.Control required dir="rtl" as="textarea" name='descriptionHe' rows={3} onChange={productHanlder} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="inputGoalsAr">
                            <Form.Label>Product Goals In Arabic</Form.Label>
                            <Form.Control required dir="rtl" as="textarea" name='goalsAr' rows={3} onChange={productHanlder} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="inputGoalsHe">
                            <Form.Label>Product Goals In Hebrew</Form.Label>
                            <Form.Control required dir="rtl" as="textarea" name='goalsHe' rows={3} onChange={productHanlder} />
                        </Form.Group>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="inputMinAgeYear">
                                <Form.Label>Minimum Age Year</Form.Label>
                                <Form.Control dir="rtl" required onWheel={(e) => e.target.blur()} type="number" step='1' name='minAgeYear' min='0' max='12' placeholder="Minimum Age Year" onChange={ageHanlder} />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="inputMinAgeMonth">
                                <Form.Label>Minimum Age Month</Form.Label>
                                <Form.Control className='marginBottom' dir="rtl" required onWheel={(e) => e.target.blur()} type="number" step='1' name='minAgeMonth' min='0' max='11' placeholder="Minimum Age Month" onChange={ageHanlder} />
                                <Form.Control.Feedback className='marginTop' type="invalid">
                                    {feedbackMinAge}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3 marginTop" controlId="inputMaxAgeYear">
                                <Form.Label>Maximum Age Year</Form.Label>
                                <Form.Control required onWheel={(e) => e.target.blur()} dir="rtl" type="number" step='1' name='maxAgeYear' min='0' max='12' placeholder="Maximum Age Year" onChange={ageHanlder} />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3 marginTop" controlId="inputMaxAgeMonth">
                                <Form.Label>Maximum Age Month</Form.Label>
                                <Form.Control required onWheel={(e) => e.target.blur()} dir="rtl" type="number" step='1' name='maxAgeMonth' min='0' max='11' placeholder="Maximum Age Month" onChange={ageHanlder} />
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3" controlId="inputPrice">
                            <Form.Label>Price</Form.Label>
                            <Form.Control required onWheel={(e) => e.target.blur()} dir="rtl" type="number" step='0.01' name='price' min='0' placeholder="Price" onChange={productHanlder} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="inputSalePrice">
                            <Form.Label>Sale Price</Form.Label>
                            <Form.Control className='marginBottom' required onWheel={(e) => e.target.blur()} dir="rtl" type="number" step='0.01' name='salePrice' min='-1' placeholder="Sale Price" onChange={productHanlder} />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackSalePrice}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Sub Category</Form.Label>
                            <Form.Select required dir="rtl" defaultValue='Sub Category' aria-label="Default select example" onChange={subCategoryIdHanlder}>
                                <option></option>
                                {subCategories?.map((subcat, i) => (
                                    <option key={i} name='subcategoryId' value={subcat._id}>{subcat.nameAr + ' - ' + subcat.nameHe}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="position-relative mb-3">
                            <Form.Label>Stock Availability</Form.Label>
                            <Form.Check required type="radio" name='inStock' value='true' label="In Stock" onChange={productHanlder} />
                            <Form.Check type="radio" name='inStock' value='false' label="Out Of Stock" onChange={productHanlder} />
                        </Form.Group>
                        <Form.Group className="position-relative mb-3" controlId="inputImage">
                            <Form.Label>Image</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    {...!showSpinner ? "" : { disabled: "disabled" }}
                                    type="file"
                                    name="file"
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={imageHanlder}
                                />
                                <Button variant="success" onClick={uploadHandler} {...images.pictureAsFile ? "" : { disabled: "disabled" }} {...!showSpinner ? "" : { disabled: "disabled" }}>
                                    upload
                                </Button>
                            </InputGroup>
                            {product.image?.map((file, i) => (
                                <div key={i}>
                                    <Image onLoad={() => setShowSpinner(false)} src={file} thumbnail className='image-thumbnail' />
                                    {!showSpinner ? <IoMdRemoveCircleOutline cursor={'pointer'} onClick={() => deleteImage(i)} /> : ""}
                                </div>
                            ))}
                        </Form.Group>
                        <hr />
                        <Button variant="primary" type='submit' {...!showSpinner ? "" : { disabled: "disabled" }}>
                            Add Product
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} {...!showSpinner ? "" : { disabled: "disabled" }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddProduct;