import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addNewSubCategory } from '../../features/subcategory/subcategorySlice';

const AddSubCategory = (nameAr, nameHe) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const { categories } = useSelector(
        (state) => state.category
    );

    const { subCategories } = useSelector(
        (state) => state.subcategory
    );

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
        setValidated(false);
    }

    const [feedbackSubcategoryNameAr, setfeedbackSubcategoryNameAr] = useState('');
    const [feedbackSubcategoryNameHe, setfeedbackSubcategoryNameHe] = useState('');

    const [subcategory, setSubcategory] = useState({
        nameAr: nameAr,
        nameHe: nameHe
    });
    const [categoryId, setCategoryId] = useState('');

    const subCategoryHanlder = (e) => {
        e.target.setCustomValidity('');
        setSubcategory((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };
    const categoryIdHanlder = (e) => {
        setCategoryId(e.target.value);
    };
    const onSubmit = (e) => {
        e.preventDefault();
        setValidated(true);

        if (subCategoryNameArIsExistValidation(subcategory.nameAr)) {
            e.target.elements.subInputNameAr.setCustomValidity(' ');
            e.target.elements.subInputNameAr.reportValidity();
            setfeedbackSubcategoryNameAr('Sub category name already exist')
        }
        if (subCategoryNameHeIsExistValidation(subcategory.nameHe)) {
            e.target.elements.subInputNameHe.setCustomValidity(' ');
            e.target.elements.subInputNameHe.reportValidity();
            setfeedbackSubcategoryNameHe('Sub category name already exist')
        }

        if (!e.currentTarget.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const obj = {
            categoryId: categoryId,
            subcategoryNameAr: subcategory.nameAr,
            subcategoryNameHe: subcategory.nameHe,
        }
        dispatch(addNewSubCategory(obj));
        handleClose();
    };

    const subCategoryNameArIsExistValidation = (name) => {
        const subCategoryNameIsExist = subCategories.find((catg) => catg.nameAr === name);
        if (subCategoryNameIsExist) {
            return true;
        }
        return false;
    }
    const subCategoryNameHeIsExistValidation = (name) => {
        const subCategoryNameIsExist = subCategories.find((catg) => catg.nameHe === name);
        if (subCategoryNameIsExist) {
            return true;
        }
        return false;
    }

    return (
        <>
            <button onClick={handleShow} className='button add-button'>Add New Sub Category</button>

            <Modal show={show} onHide={handleClose} size='lg' backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Sub Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="subInputNameAr">
                            <Form.Label>Sub Category Name In Arabic</Form.Label>
                            <Form.Control className='marginBottom' dir="rtl" required type="text" name='nameAr' placeholder="Sub Category Name In Arabic" onChange={subCategoryHanlder} autoFocus />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackSubcategoryNameAr}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="subInputNameHe">
                            <Form.Label>Sub Category Name In Hebrew</Form.Label>
                            <Form.Control className='marginBottom' dir="rtl" required type="text" name='nameHe' placeholder="Sub Category Name In Hebrew" onChange={subCategoryHanlder} />
                            <Form.Control.Feedback className='marginTop' type="invalid">
                                {feedbackSubcategoryNameHe}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Select required dir="rtl" aria-label="Default select example" onChange={categoryIdHanlder}>
                                <option></option>
                                {categories?.map((category, i) => (
                                    <option key={i} name='categoryId' value={category._id}>{category.nameAr + ' - ' + category.nameHe}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Button variant="primary" type='submit'>
                            Add Sub Category
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddSubCategory
