import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Navbar.css'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../features/auth/authSlice";

const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector(
        (state) => state.auth
    );

    const handleNavigateProducts = () => {
        navigate('/products')
    }
    const handleNavigateHome = () => {
        navigate('/home')
    }
    const handleNavigateCategories = () => {
        navigate('/categories')
    }
    const handleNavigateSubCategories = () => {
        navigate('/subcategories')
    }
    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(logout());
        navigate('/');
    };

    return (
        <>
            {user ? (<>
                <Nav className="flex-column sideNavbar">
                    <Nav.Link onClick={handleNavigateHome} className='nav-link'><img src={window.location.origin + '/logo.png'} alt="montekids" width={200} className='' /></Nav.Link>
                    <Nav.Link onClick={handleNavigateCategories} className='nav-link'>Categories</Nav.Link>
                    <Nav.Link onClick={handleNavigateSubCategories} className='nav-link'>Sub Categories</Nav.Link>
                    <Nav.Link onClick={handleNavigateProducts} className='nav-link'>Products</Nav.Link>
                    <Nav.Link onClick={handleLogout} className='nav-link logout'>Logout</Nav.Link>
                </Nav>
            </>) : (<></>)}
        </>
    );
}

export default Navbar;
