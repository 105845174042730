import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryService from "./categoryService";

const initialState = {
    categories: [],
    isSuccessCategory: false,
    isErrorCategory: false,
    isLoadingCategory: false,
    messageCategory: "",
};

//Getting All Categories
export const getAllCategories = createAsyncThunk("category/getAllCategories", async (thunkAPI) => {
    try {
        return await categoryService.getAllCategories();
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
//Add New Category
export const addNewCategory = createAsyncThunk("category/addNewCategory", async (category, thunkAPI) => {
    try {
        return await categoryService.addNewCategory(category);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
//Update Category
export const updateCategory = createAsyncThunk("category/updateCategory", async (obj, thunkAPI) => {
    try {
        return await categoryService.updateCategory(obj);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        resetCategory: (state) => {
            state.isLoadingCategory = false;
            state.isErrorCategory = false;
            state.isSuccessCategory = false;
            state.messageCategory = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCategories.pending, (state) => {
                state.isLoadingCategory = true;
            })
            .addCase(getAllCategories.fulfilled, (state, action) => {
                state.isLoadingCategory = false;
                state.isSuccessCategory = true;
                state.categories = action.payload.data;
            })
            .addCase(getAllCategories.rejected, (state, action) => {
                state.isLoadingCategory = false;
                state.isErrorCategory = true;
                state.messageCategory = action.payload.Message;
                state.categories = [];
            })
            .addCase(addNewCategory.pending, (state) => {
                state.isLoadingCategory = true;
            })
            .addCase(addNewCategory.fulfilled, (state, action) => {
                state.isLoadingCategory = false;
                state.isSuccessCategory = true;
                state.categories = [...state.categories, action.payload.data];
            })
            .addCase(addNewCategory.rejected, (state, action) => {
                state.isLoadingCategory = false;
                state.isErrorCategory = true;
                state.messageCategory = action.payload.Message;
            })
            .addCase(updateCategory.pending, (state) => {
                state.isLoadingCategory = true;
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                state.isLoadingCategory = false;
                state.isSuccessCategory = true;
                state.categories = state.categories.map(element => {
                    if (element._id === action.payload.data._id) {
                        return action.payload.data;
                    }
                    return element;
                });
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.isLoadingCategory = false;
                state.isErrorCategory = true;
                state.messageCategory = action.payload.Message;
            })
    },
});

export const { resetCategory } = categorySlice.actions;
export default categorySlice.reducer;