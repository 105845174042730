import { useState, useEffect } from "react";
import { MDBContainer, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, reset } from "../../features/auth/authSlice";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isError, message } = useSelector(
        (state) => state.auth
    );

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const { email, password } = formData;

    useEffect(() => {
        if (user && window.location.pathname === '/') {
            navigate("/home");
        }
        dispatch(reset());
    }, [user, navigate, dispatch]);

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(login(formData));
    };
    const onChange = (e) => {
        setFormData((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };

    return (
        <>
            {isError && alert(message)}
            {!user ? (<>
                <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
                    <MDBInput wrapperClass='mb-4' label='Email address' id='floatingEmail' name="email" defaultValue={email} type='email' onChange={onChange} required />
                    <MDBInput wrapperClass='mb-4' label='Password' id='floatingPassword' name="password" defaultValue={password} type='password' onChange={onChange} required />
                    <MDBBtn className="mb-4" type='submit' onClick={onSubmit}>Sign in</MDBBtn>
                </MDBContainer>
            </>) : (<></>)}
        </>
    );
}

export default Login;