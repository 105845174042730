import axios from 'axios';

const API_URL = '/api/products';

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('UserToken')}`,
    },
};

const getAllProducts = async () => {
    const response = await axios.get(process.env.REACT_APP_HOST_URL + API_URL + '/getproducts');
    return response.data;
}
const addNewProduct = async (obj) => {
    const product = {
        catalogNumber: obj.catalogNumber,
        nameAr: obj.nameAr,
        nameHe: obj.nameHe,
        image: obj.image,
        video: obj.video,
        descriptionAr: obj.descriptionAr,
        descriptionHe: obj.descriptionHe,
        price: obj.price,
        salePrice: obj.salePrice,
        inStock: obj.inStock,
        isPopular: obj.isPopular,
        goalsAr: obj.goalsAr,
        goalsHe: obj.goalsHe,
        minAge: obj.minAge,
        maxAge: obj.maxAge
    }
    const response = await axios.post(process.env.REACT_APP_HOST_URL + API_URL + '/addproduct/' + obj.subcategoryId, product, config);
    return response.data;
}
const updateProduct = async (obj) => {
    const product = {
        catalogNumber: obj.catalogNumber,
        nameAr: obj.nameAr,
        nameHe: obj.nameHe,
        image: obj.image,
        video: obj.video,
        descriptionAr: obj.descriptionAr,
        descriptionHe: obj.descriptionHe,
        price: obj.price,
        salePrice: obj.salePrice,
        inStock: obj.inStock,
        isPopular: obj.isPopular,
        goalsAr: obj.goalsAr,
        goalsHe: obj.goalsHe,
        minAge: obj.minAge,
        maxAge: obj.maxAge,
        subcategory: obj.subcategory
    }
    const response = await axios.put(process.env.REACT_APP_HOST_URL + API_URL + '/updateproduct/' + obj.id, product, config);
    return response.data;
}
const deleteProduct = async (id) => {
    const response = await axios.delete(process.env.REACT_APP_HOST_URL + API_URL + '/deleteproduct/' + id, config);
    return response.data;
}
const productService = {
    getAllProducts,
    addNewProduct,
    updateProduct,
    deleteProduct
}

export default productService;