import axios from 'axios';
import './ProductCards.css'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineDelete } from 'react-icons/ai'
import { deleteProduct, updateProduct } from '../../features/product/productSlice';
import { Col, Image, Row } from 'react-bootstrap';
import { IoMdRemoveCircleOutline } from 'react-icons/io'

import InputGroup from 'react-bootstrap/InputGroup';

const ProductCards = (card) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setValidated(false);
        setImages({});
        product.image = card.image
    }
    const [showSpinner, setShowSpinner] = useState(false);

    const [feedbackCatalogNumber, setfeedbackCatalogNumber] = useState('');
    const [feedbackMinAge, setfeedbackMinAge] = useState('');
    const [feedbackSalePrice, setfeedbackSalePrice] = useState('');

    const { products } = useSelector(
        (state) => state.product
    );

    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = (e) => {
        e.stopPropagation();
        setShowDelete(true);
    }

    const [product, setProduct] = useState({
        catalogNumber: "",
        nameAr: "",
        nameHe: "",
        image: card.image,
        video: "",
        descriptionAr: "",
        descriptionHe: "",
        price: "",
        salePrice: "",
        inStock: "",
        isPopular: "",
        goalsAr: "",
        goalsHe: "",
        minAge: "",
        maxAge: ""
    });

    const { subCategories } = useSelector(
        (state) => state.subcategory
    );
    const getMinAgeYear = () => {
        return parseInt(card.minAge);
    }
    const getMaxAgeYear = () => {
        return parseInt(card.maxAge);
    }
    const getMinAgeMonth = () => {
        const minagemonth = parseFloat(card.minAge) % 1 * 12;
        return Math.round(minagemonth);
    }
    const getMaxAgeMonth = () => {
        const maxagemonth = parseFloat(card.maxAge) % 1 * 12;
        return Math.round(maxagemonth);
    }
    const [age, setAge] = useState({
        minAgeYear: getMinAgeYear(),
        minAgeMonth: getMinAgeMonth(),
        maxAgeYear: getMaxAgeYear(),
        maxAgeMonth: getMaxAgeMonth()
    });

    const [subcategoryId, setCategoryId] = useState('');

    const productHanlder = (e) => {
        e.target.setCustomValidity('');
        if (e.target.name === 'salePrice') {
            setfeedbackSalePrice("");
        }
        if (e.target.name === 'price' || e.target.name === 'salePrice') {
            setProduct((prevState) => {
                return {
                    ...prevState,
                    [e.target.name]: parseFloat(e.target.value),
                };
            });
        } else {
            setProduct((prevState) => {
                return {
                    ...prevState,
                    [e.target.name]: e.target.value,
                };
            });
        }
    };

    const subcategoryIdHanlder = (e) => {
        setCategoryId(e.target.value);
    };

    useEffect(() => {
        // if ((age.minAgeYear || age.minAgeYear === 0) && (age.minAgeMonth || age.minAgeMonth === 0) && (age.maxAgeYear || age.maxAgeYear === 0) && (age.maxAgeMonth || age.maxAgeMonth === 0)) {
        mergeMinMaxAge(age);
        // }
    }, [age]);

    const ageHanlder = (e) => {
        e.target.setCustomValidity('');
        if (e.target.name === 'minAgeMonth') {
            setfeedbackMinAge("");
        }
        setAge((prevState) => {
            return {
                ...prevState,
                [e.target.name]: parseFloat(e.target.value),
            };
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setValidated(true);
        e.target.elements.updateProInputMinAgeMonth.setCustomValidity('');
        e.target.elements.updateProInputSalePrice.setCustomValidity('');

        if (catalogNumberIsExistValidation(product.catalogNumber)) {
            e.target.elements.updateProInputCatNum.setCustomValidity(' ');
            e.target.elements.updateProInputCatNum.reportValidity();
            setfeedbackCatalogNumber('Catalog number already exist');
        }

        if (ageValidation(product.minAge, product.maxAge)) {
            e.target.elements.updateProInputMinAgeMonth.setCustomValidity(' ');
            e.target.elements.updateProInputMinAgeMonth.reportValidity();
            setfeedbackMinAge('Min age must be smaller than max age')
        }
        if (minMaxAgeBiggerThan12Validation(product.minAge, product.maxAge)) {
            e.target.elements.updateProInputMinAgeMonth.setCustomValidity(' ');
            e.target.elements.updateProInputMinAgeMonth.reportValidity();
            setfeedbackMinAge('Ages cant be bigger than 12Y 0M')
        }
        if ((!product.minAge && product.minAge !== 0) || (!product.maxAge && product.maxAge !== 0)) {
            e.target.elements.updateProInputMinAgeMonth.setCustomValidity(' ');
            e.target.elements.updateProInputMinAgeMonth.reportValidity();
            setfeedbackMinAge('Please enter all MIN MAX age`s values')
        }
        if (priceValidation(product.price, product.salePrice)) {
            e.target.elements.updateProInputSalePrice.setCustomValidity(' ');
            e.target.elements.updateProInputSalePrice.reportValidity();
            setfeedbackSalePrice('Sale price must be smaller than price')
        }
        if (productNullValidation()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (product.image && product.image.length === 0) {
            e.target.elements.updateProInputImage.setCustomValidity(' ');
            e.target.elements.updateProInputImage.reportValidity();
        } else {
            e.target.elements.updateProInputImage.setCustomValidity('');
            e.target.elements.updateProInputImage.reportValidity();
        }

        if (!e.currentTarget.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const obj = {
            ...product,
            id: card._id,
            subcategory: subcategoryId
        }

        dispatch(updateProduct(obj));
        handleClose();
    };

    const deleteCardHandle = (e) => {
        e.stopPropagation();
        dispatch(deleteProduct(card._id));
        handleCloseDelete();
    }

    const catalogNumberIsExistValidation = (catalogNumber) => {
        const catalogNumberIsExist = products.find((prod) => prod.catalogNumber === catalogNumber);
        if (catalogNumberIsExist) {
            return true;
        }
        return false;
    }

    const mergeMinMaxAge = (age) => {
        const minage = (age.minAgeYear + age.minAgeMonth / 12);
        const maxage = (age.maxAgeYear + age.maxAgeMonth / 12);
        setProduct((prevState) => {
            return {
                ...prevState,
                minAge: parseFloat(minage)
            };
        });
        setProduct((prevState) => {
            return {
                ...prevState,
                maxAge: parseFloat(maxage)
            };
        });
        return true;
    }

    const ageValidation = (min, max) => {
        if (min < 0 || max < 0 || (min !== 0 && !min) || (max !== 0 && !max) || min > 12 || max > 12) {
            return false;
        } else if (min <= max) {
            return false;
        }
        return true;
    }

    const minMaxAgeBiggerThan12Validation = (min, max) => {
        if ((min || min === 0) && (max || max === 0)) {
            if (min <= 12 && max <= 12) {
                return false;
            }
            return true;
        }
        return false;
    }

    const priceValidation = (price, salePrice) => {
        if (price < 0 || salePrice < -1 || (price !== 0 && !price) || (salePrice !== 0 && !salePrice)) {
            return false;
        } else if (price > salePrice) {
            return false;
        }
        return true;
    }
    const productNullValidation = () => {
        for (const i in product) {
            if (product[i] !== '') {
                return false;
            }
        }
        if (subcategoryId !== '') {
            return false
        }
        return true;
    }

    const subcategoryName = () => {
        const subcat = subCategories?.find((sc) => sc._id === card.subcategory)
        return `${subcat?.nameAr + ' - ' + subcat?.nameHe}`
    }

    const [images, setImages] = useState({});

    const imageHanlder = (e) => {
        setImages({});
        if (e.target.files.length !== 0) {
            if (e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg") {
                e.target.setCustomValidity('');
                e.target.reportValidity();
                setImages({
                    picturePreview: URL.createObjectURL(e.target.files[0]),
                    pictureAsFile: e.target.files[0]
                })
            }
        }
    };
    const uploadHandler = async (e) => {
        setShowSpinner(true);
        const formData = new FormData();
        formData.append(
            "file",
            images.pictureAsFile
        );
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem('UserToken')}`,
            },
        };
        const response = await axios.post(process.env.REACT_APP_HOST_URL + '/api/s3/upload', formData, config);
        if (response) {
            const arr = [...product.image, response.data.s3ImgUrl];
            setProduct((prevState) => {
                return {
                    ...prevState,
                    image: arr,
                };
            });
        }
        return;
    };
    /**
    * Deletes an image from the product's image array at the specified index.
    * @param {number} i - The index of the image to be deleted.
    * @returns {void}
    */
    const deleteImage = (i) => {
        let arr = [...product.image];
        arr.splice(i, 1);
        setProduct((prevState) => {
            return {
                ...prevState,
                image: arr,
            };
        });
    }




    return (
        <>
            <div onClick={handleShow} className="flexColStart p-card">
                <img src={`${card.image[0]}`} alt="error" className={card.inStock ? '' : 'grayscale'} />
                <span className='secondaryText flexCenter p-price'>
                    <div>
                        <span className={card.inStock ? '' : 'grayscale'}>₪{card.price} <br /> ₪{card.salePrice}</span>
                    </div>
                    <div className='add-to-cart-icon'>
                        <AiOutlineDelete onClick={handleShowDelete} className='removeicon' />
                    </div>
                </span>
                <span className={card.inStock ? 'primaryText' : 'primaryText grayscale'}>CN: {card.catalogNumber}</span>
                <span className={card.inStock ? 'primaryText' : 'primaryText grayscale'}>{card.nameAr}</span>
                <span className={card.inStock ? 'secondaryText' : 'secondaryText grayscale'}>{getMinAgeYear()}Y {getMinAgeMonth()}M - {getMaxAgeYear()}Y {getMaxAgeMonth()}M</span>
                <span className={card.inStock ? 'secondaryText' : 'secondaryText grayscale'}>{subcategoryName()}</span>
                <div className={card.inStock ? 'display-none' : 'outOfStock'}>Out of Stock</div>
            </div>
            <>
                <Modal show={show} onHide={handleClose} size='lg' backdrop='static'>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated} onSubmit={onSubmit}>
                            <Form.Group className="mb-3" controlId="updateProInputCatNum">
                                <Form.Label>Catalog Number</Form.Label>
                                <Form.Control className='marginBottom' dir="rtl" type="text" name='catalogNumber' placeholder={card.catalogNumber} onChange={productHanlder} autoFocus />
                                <Form.Control.Feedback className='marginTop' type="invalid">
                                    {feedbackCatalogNumber}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="updateProInputNameAr">
                                <Form.Label>Product Name In Arabic</Form.Label>
                                <Form.Control type="text" dir="rtl" name='nameAr' placeholder={card.nameAr} onChange={productHanlder} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="updateProInputNameHe">
                                <Form.Label>Product Name In Hebrew</Form.Label>
                                <Form.Control type="text" dir="rtl" name='nameHe' placeholder={card.nameHe} onChange={productHanlder} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="updateProInputDesAr">
                                <Form.Label>Product Description In Arabic</Form.Label>
                                <Form.Control as="textarea" dir="rtl" name='descriptionAr' placeholder={card.descriptionAr} rows={3} onChange={productHanlder} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="updateProInputDesHe">
                                <Form.Label>Product Description In Hebrew</Form.Label>
                                <Form.Control as="textarea" dir="rtl" name='descriptionHe' placeholder={card.descriptionHe} rows={3} onChange={productHanlder} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="updateProInputGoalsAr">
                                <Form.Label>Product Goals In Arabic</Form.Label>
                                <Form.Control as="textarea" dir="rtl" name='goalsAr' placeholder={card.goalsAr} rows={3} onChange={productHanlder} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="updateProInputGoalsHe">
                                <Form.Label>Product Goals In Hebrew</Form.Label>
                                <Form.Control as="textarea" dir="rtl" name='goalsHe' placeholder={card.goalsHe} rows={3} onChange={productHanlder} />
                            </Form.Group>
                            <Row className="mb-3">
                                <Form.Group as={Col} className="mb-3" controlId="updateProInputMinAgeYear">
                                    <Form.Label>Minimum Age Year</Form.Label>
                                    <Form.Control dir="rtl" onWheel={(e) => e.target.blur()} type="number" step='1' name='minAgeYear' min='0' max='12' placeholder={getMinAgeYear()} onChange={ageHanlder} />
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3" controlId="updateProInputMinAgeMonth">
                                    <Form.Label>Minimum Age Month</Form.Label>
                                    <Form.Control className='marginBottom' dir="rtl" onWheel={(e) => e.target.blur()} type="number" step='1' name='minAgeMonth' min='0' max='11' placeholder={getMinAgeMonth()} onChange={ageHanlder} />
                                    <Form.Control.Feedback className='marginTop' type="invalid">
                                        {feedbackMinAge}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} className="mb-3 marginTop" controlId="updateProInputMaxAgeYear">
                                    <Form.Label>Maximum Age Year</Form.Label>
                                    <Form.Control onWheel={(e) => e.target.blur()} dir="rtl" type="number" step='1' name='maxAgeYear' min='0' max='12' placeholder={getMaxAgeYear()} onChange={ageHanlder} />
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3 marginTop" controlId="updateProInputMaxAgeMonth">
                                    <Form.Label>Maximum Age Month</Form.Label>
                                    <Form.Control onWheel={(e) => e.target.blur()} dir="rtl" type="number" step='1' name='maxAgeMonth' min='0' max='11' placeholder={getMaxAgeMonth()} onChange={ageHanlder} />
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3" controlId="updateProInputPrice">
                                <Form.Label>Price</Form.Label>
                                <Form.Control onWheel={(e) => e.target.blur()} dir="rtl" type="number" step='0.01' name='price' min='0' placeholder={card.price} onChange={productHanlder} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="updateProInputSalePrice">
                                <Form.Label>Sale Price</Form.Label>
                                <Form.Control onWheel={(e) => e.target.blur()} dir="rtl" className='marginBottom' type="number" step='0.01' name='salePrice' min='-1' placeholder={card.salePrice} onChange={productHanlder} />
                                <Form.Control.Feedback className='marginTop' type="invalid">
                                    {feedbackSalePrice}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Sub Category</Form.Label>
                                <Form.Select aria-label="Default select example" dir="rtl" onChange={subcategoryIdHanlder}>
                                    <option></option>
                                    {subCategories?.map((subcat, i) => (
                                        <option key={i} name="subcategoryId" value={subcat._id}>{subcat.nameAr + ' - ' + subcat.nameHe}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="position-relative mb-3">
                                <Form.Label>Stock Availability</Form.Label>
                                <Form.Check type="radio" name='inStock' value="true" label="In Stock" onChange={productHanlder} />
                                <Form.Check type="radio" name='inStock' value="false" label="Out Of Stock" onChange={productHanlder} />
                            </Form.Group>

                            <Form.Group className="position-relative mb-3" controlId="updateProInputImage">
                                <Form.Label>Image</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        {...!showSpinner ? "" : { disabled: "disabled" }}
                                        type="file"
                                        name="file"
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={imageHanlder}
                                    />
                                    <Button variant="success" onClick={uploadHandler} {...images.pictureAsFile ? "" : { disabled: "disabled" }} {...!showSpinner ? "" : { disabled: "disabled" }}>
                                        upload
                                    </Button>
                                </InputGroup>
                                {product.image?.map((file, i) => (
                                    <div key={i} >
                                        <Image onLoad={() => setShowSpinner(false)} src={file} thumbnail className='image-thumbnail' />
                                        {!showSpinner ? <IoMdRemoveCircleOutline cursor={'pointer'} onClick={() => deleteImage(i)} /> : ""}
                                    </div>
                                ))}
                            </Form.Group>
                            <hr />
                            <Button variant="primary" type='submit' {...!showSpinner ? "" : { disabled: "disabled" }}>
                                Save Changes
                            </Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose} {...!showSpinner ? "" : { disabled: "disabled" }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDelete} onHide={handleCloseDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><span>Are you sure you want to delete <span style={{ color: "red" }}>{card.nameAr}</span></span></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Close
                        </Button>
                        <Button variant="primary" className='btn-danger' onClick={deleteCardHandle}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

            </>
        </>
    )
}

export default ProductCards;
