import axios from 'axios';

const API_URL = '/api/users';

//login user
const login = async (userData) => {
    const response = await axios.post(process.env.REACT_APP_HOST_URL + API_URL + '/login', userData);
    if (response.data) {
        localStorage.setItem('User', JSON.stringify(response.data.data));
        localStorage.setItem('UserToken', response.data.token);
        window.location.reload();
    }
    return response.data;
}

//Logout user
const logout = () => {
    localStorage.removeItem('User');
    localStorage.removeItem('UserToken');
}

const authService = {
    logout,
    login
}

export default authService;