import { useEffect } from 'react';
import AddProduct from './AddProduct';
import { useNavigate } from 'react-router-dom';
import ProductCards from '../ProductCards/ProductCards';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../../features/auth/authSlice';
import { getAllProducts } from '../../features/product/productSlice';
import { Spinner } from 'react-bootstrap';
import { getAllSubCategories } from '../../features/subcategory/subcategorySlice';

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(
    (state) => state.auth
  );
  const { products, isLoadingProduct } = useSelector(
    (state) => state.product
  );
  const { subCategories } = useSelector(
    (state) => state.subcategory
  );

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
    if (products.length === 0) {
      dispatch(getAllProducts());
    }
    if (subCategories.length === 0) {
      dispatch(getAllSubCategories());
    }
    dispatch(reset());
  }, [user, products.length, subCategories.length, dispatch, navigate]);

  const inStockProductsCount = () => {
    const inStockProducts = products?.filter((prod) => prod.inStock);
    return inStockProducts?.length;
  }

  const outOfStockProductsCount = () => {
    const inStockProducts = products?.filter((prod) => !prod.inStock);
    return inStockProducts?.length;
  }

  return (
    <>
      {isLoadingProduct ? <Spinner /> : (
        <div>
          <div>
            <AddProduct />
          </div>
          <div>
            <div>
              <div className='primaryText text'>There are {products.length} Products</div>
              <div className='primaryText text'>There are {inStockProductsCount()} Products In Stock</div>
              <div className='primaryText text'>And {outOfStockProductsCount()} Products <span className='redtext'>Out Of Stock</span></div>
              <div className='flexCenter paddings products'>
                {products?.length === 0 ? "There Are No Products Yet!" : products.map((card, i) => (
                  <ProductCards key={i} {...card} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Products;
