import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subcategoryService from "./subcategoryService";

const initialState = {
    subCategories: [],
    isSuccessSubCategory: false,
    isErrorSubCategory: false,
    isLoadingSubCategory: false,
    messageSubCategory: "",
};

//Getting All Sub Categories
export const getAllSubCategories = createAsyncThunk("subcategory/getAllSubCategories", async (thunkAPI) => {
    try {
        return await subcategoryService.getAllSubCategories();
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
export const addNewSubCategory = createAsyncThunk("category/addNewSubCategory", async (obj, thunkAPI) => {
    try {
        return await subcategoryService.addNewSubCategory(obj);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
export const updateSubCategory = createAsyncThunk("category/updateSubCategory", async (obj, thunkAPI) => {
    try {
        return await subcategoryService.updateSubCategory(obj);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const subcategorySlice = createSlice({
    name: "subcategory",
    initialState,
    reducers: {
        resetSubcategory: (state) => {
            state.isLoadingSubCategory = false;
            state.isErrorSubCategory = false;
            state.isSuccessSubCategory = false;
            state.messageSubCategory = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllSubCategories.pending, (state) => {
                state.isLoadingSubCategory = true;
            })
            .addCase(getAllSubCategories.fulfilled, (state, action) => {
                state.isLoadingSubCategory = false;
                state.isSuccessSubCategory = true;
                state.subCategories = action.payload.data;
            })
            .addCase(getAllSubCategories.rejected, (state, action) => {
                state.isLoadingSubCategory = false;
                state.isErrorSubCategory = true;
                state.messageSubCategory = action.payload.Message;
                state.subCategories = [];
            })
            .addCase(addNewSubCategory.pending, (state) => {
                state.isLoadingSubCategory = true;
            })
            .addCase(addNewSubCategory.fulfilled, (state, action) => {
                state.isLoadingSubCategory = false;
                state.isSuccessSubCategory = true;
                state.subCategories = [...state.subCategories, action.payload.data];
            })
            .addCase(addNewSubCategory.rejected, (state, action) => {
                state.isLoadingSubCategory = false;
                state.isErrorSubCategory = true;
                state.messageSubCategory = action.payload.Message;
            })
            .addCase(updateSubCategory.pending, (state) => {
                state.isLoadingSubCategory = true;
            })
            .addCase(updateSubCategory.fulfilled, (state, action) => {
                state.isLoadingSubCategory = false;
                state.isSuccessSubCategory = true;
                state.subCategories = state.subCategories.map(element => {
                    if (element._id === action.payload.data._id) {
                        return action.payload.data;
                    }
                    return element;
                });
            })
            .addCase(updateSubCategory.rejected, (state, action) => {
                state.isLoadingSubCategory = false;
                state.isErrorSubCategory = true;
                state.messageSubCategory = action.payload.Message;
            })
    },
});

export const { resetSubcategory } = subcategorySlice.actions;
export default subcategorySlice.reducer;